/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import config from '../../config.json'
import axios from 'axios'
import { Masthead } from "../masthead";
import { CharactersCards } from "../characters-cards";
import { DiscordWidget } from "../discord-widget";
import Box from "@mui/material/Box";
import { ServerNews } from "../server-news";
import { HomeCards } from '../home-cards';
class Home extends Component {
    state = {
        onlinePlayers: 'LOADING',
        serverStatus: 'LOADING',
        lastGuildWarWinner: 'LOADING',
        lastTwinCityWinner: 'LOADING',
        lastCounterClockWinner: 'LOADING',
    }

    apiEndPoint = config.apiEndPoint + 'statistics'

    async componentDidMount() {
        try {
            const result = await axios.get(this.apiEndPoint)
            if (result.status == 200) {
                const data = result.data
                if (data?.serverOnline) {
                    this.setState({
                        serverStatus: 'ONLINE',
                        onlinePlayers: data?.onlinePlayers ?? 0
                    })
                }
                else {
                    this.setState({
                        serverStatus: 'OFFLINE',
                        onlinePlayers: 0
                    })
                }
            }
        } catch {
        }
    }

    getServerState() {
        const currentState = this.state.serverStatus
        if (currentState == 'ONLINE')
        {
            return `There are ${this.state.onlinePlayers} players online`;
        }

        // return `The server is offline`
        return `The server is online`;
    }

    render() {
        const serverState = this.getServerState();
        console.log('Server=', serverState)
        return (
            <Box style={{ paddingBottom: 50, backgroundColor: '#15104a' }}>
                <Masthead serverState={serverState} />
                {/* <HomeCards /> */}
                {/* <DiscordWidget /> */}
                <ServerNews />
            </Box>

        )
    }
}

export default Home
