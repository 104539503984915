import mastheadImg from '../assets/s1.jpg'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Masthead = ({ serverState }) => {

    const navigate = useNavigate();
    return (
        <Box style={{
            background: `url('${mastheadImg}')`,
            height: 750,
            position: 'relative'
        }}>
            <Box style={{
                position: 'absolute',
                color: "white",
                top: '40%',
                marginLeft: '45px'
            }}>
               
                <Box style={{
                    borderLeft: '1px solid',
                    paddingLeft: 30,
                    marginTop: 20
                }}>
                    <h1>Conquer Warlords</h1>
                    {/* <h5>Asia Hosted</h5>
                    <h5>sample test</h5> */}
                    <span>{serverState}</span>
                </Box>
                <Box style={{
                    marginTop: 50
                }}>
                    <Button variant="contained" style={{
                        backgroundColor: '#0d0b26'
                    }} onClick={() => navigate('/downloads')} size="large">DOWNLOAD NOW</Button>
                  

                </Box>
                &nbsp; &nbsp;
               {/*  <Box style={{
                    marginTop: 50
                }}>
                    <Button variant="contained" style={{
                        backgroundColor: '#0d0b26'
                    }} size="large"></Button>


                </Box> */}
            </Box>
        </Box>

    )

};